import axios from '@/axios.js'

export default {
  fetchAll ({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!state.documents.length) {
        axios.get(`${process.env.VUE_APP_API_URL}/documents/templates`)
          .then((response) => {
            commit('REFRESH', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      } else {
        resolve(state.documents)
      }
    })
  },
  // fetchDocument ({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.get(`${process.env.VUE_APP_API_URL}/document/${payload}/edit`)
  //       .then((res) => {
  //         commit('SET', res.data)
  //         resolve(res)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/document/template`, payload)
        .then((response) => {
          commit('STORE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/document/template/${payload.id}`, payload)
        .then((response) => {
          commit('SET', response.data)
          commit('UPDATE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // fetchGrid ({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.post(`${process.env.VUE_APP_API_URL}/documents`, payload)
  //       .then((response) => {
  //         commit('SET_DOCUMENTS_GRID', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/document/template/${payload}`)
        .then((response) => {
          commit('DELETE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}

import Vue from 'vue'

export default {
  SET (state, payload) {
    state.document = Object.assign({}, state.document, payload) // precisa ser assim para manter a reatividade
    state.documentFiltered = Object.assign({}, state.documentFiltered, payload) // precisa ser assim para manter a reatividade
  },
  REFRESH (state, payload) {
    state.documents = payload
    state.documentsFiltered = payload
  },
  SET_DOCUMENTS_GRID (state, payload) {
    state.documentsGrid = payload
  },
  STORE (state, payload) {
    state.documents.push(payload) // precisa ser assim para manter a reatividade
    // state.documentsFiltered.push(payload) // tem que ficar fora se nao aparecem 2 cadastros ao criar um doc

    state.documents.sort((a, b) => { return a.title.localeCompare(b.title) }) // Ordena por nome
    // state.documentsFiltered.sort((a, b) => { return a.title.localeCompare(b.title) }) // Ordena por nome // tem que ficar fora se nao aparecem 2 cadastros ao criar um doc

    state.documentsGrid.data.push(payload)
    state.documentsGrid.data.sort((a, b) => { return a.title.localeCompare(b.title) }) // Ordena por nome
    state.documentsGrid.total = state.documentsGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.documents.findIndex(o => o.id === payload.id)
    if (idx >= 0) {
      Vue.set(state.documents, idx, payload)
      state.documents.sort((a, b) => { return a.title.localeCompare(b.title) }) // Ordena por nome
    }
    const idx2 = state.documentsFiltered.findIndex(o => o.id === payload.id)
    if (idx2 >= 0) {
      Vue.set(state.documentsFiltered, idx2, payload)
      state.documentsFiltered.sort((a, b) => { return a.title.localeCompare(b.title) }) // Ordena por nome
    }

    const idx1 = state.documentsGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) {
      Vue.set(state.documentsGrid.data, idx1, payload)
      state.documentsGrid.data.sort((a, b) => { return a.title.localeCompare(b.title) }) // Ordena por nome
    }
  },
  DELETE (state, payload) {
    const idx1 = state.documents.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.documents.splice(idx1, 1) // precisa ser assim para manter a reatividade
    const idx2 = state.documentsFiltered.findIndex(o => o.id === payload)
    if (idx2 >= 0) state.documentsFiltered.splice(idx1, 1) // precisa ser assim para manter a reatividade

    const idx = state.documentsGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.documentsGrid.data.splice(idx, 1) // precisa ser assim para manter a reatividade
      state.documentsGrid.total = state.documentsGrid.total - 1
    }
  },
  FILTER_BY_TITLE (state, payload) {
    state.documentsFiltered = state.documents.filter(a => a.title.toUpperCase().indexOf(payload.toUpperCase()) > -1)
  }
}

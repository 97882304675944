export default {
  documents: [],
  documentsFiltered: [],
  document: {},
  documentsGrid: {
    data: [],
    total: 0
  }


}
